import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledEthicalCarousel = styled.div`
  text-align: center;
`;

export const StyledEthicalCarouselPagination = styled.div`
  bottom: -300px;

  .swiper-pagination-bullet {
    width: ${Rem(18)};
    height: ${Rem(5)};
    margin: 0 ${Rem(8)} !important;
    border-radius: ${Rem(10)};
    background-color: ${Colors.gray};
    cursor: pointer;

    @media (${BP.ipad}) {
      width: ${Rem(50)};
      margin: 0 ${Rem(24)} !important;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: ${Colors.black};
  }
`;

export const StyledEthicalCarouselItems = styled.div`
  padding-bottom: ${Rem(40)};

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(80)};
  }
`;

export const StyledEthicalCarouselItem = styled.div`
  padding: ${Rem(20)} ${Rem(40)} ${Rem(64)};
`;

export const StyledEthicalCarouselItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${Rem(130)};
`;

export const StyledEthicalCarouselItemTitle = styled.h3`
  font-size: ${Rem(30)};
  line-height: ${Rem(56)};
`;

export const StyledEthicalCarouselItemDescription = styled.p`
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
  font-weight: 300;
`;
